import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import Footer from "../components/Footer/Footer";
import { ReactNode, useEffect, useState } from "react";
import { Post } from "../helpers/Http";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
} from "@mui/icons-material";
import { Navabar } from "../components/Navbar/Navbar";

type PatientType = "New" | "Existing";
interface Customer {
  name: string;
  email: string;
  phone: string;
  country: string;
  patientType: PatientType;
  subsribedToNewsletter?: boolean;
}
interface Country {
  code: string;
  phoneCode: string;
  mobilePhonePattern: string;
  className: string;
}

const countries: Country[] = [
  {
    code: "IR",
    phoneCode: "+385",
    mobilePhonePattern: "8[2-9]\\d{7}",
    className: "fi fi-ie fis",
  },
  {
    code: "UK",
    phoneCode: "+44",
    mobilePhonePattern: "07\\d{9}",
    className: "fi fi-gb fis",
  },
];

export default function BookAppointment() {
  const [country, setCountry] = useState<Country>(countries[0]);
  const [phoneValid, setPhoneValidation] = useState<boolean>(true);
  const [phoneTf, setPhone] = useState<string>("");
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [bookedSuccessfully, setBookedSuccessfully] = useState<boolean>(false);
  const [customer, setCustomer] = useState<Customer>({
    patientType: "New",
    country: country.code,
    subsribedToNewsletter: true,
  } as Customer);

  const handleToggleUpdate = (
    event: React.MouseEvent<HTMLElement>,
    patientType: PatientType
  ) => {
    setCustomer({ ...customer, patientType: patientType });
  };

  useEffect(() => {
    setCustomer({ ...customer, phone: `${country.phoneCode}${phoneTf}` });
  }, [phoneTf])

  function handleChange(
    event: SelectChangeEvent<string>,
    child: ReactNode
  ): void {
    let country = countries.find(
      (country) => country.code === event.target.value
    );
    setCountry(country!);
    setCustomer({
      ...customer,
      country: country!.code,
      phone: `${country!.phoneCode}${phoneTf}`,
    });
  }

  // handle submit method
  async function handleSubmit(
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> {
    event.preventDefault();
    setApiLoading(true);
    const response = await Post(
      "https://bookcustomer-a5tgolngta-uc.a.run.app/",
      customer
    );
    if (response.httpType === "success") {
      setBookedSuccessfully(true);
    }
    setApiLoading(false);
  }

  useEffect(() => {
    setApiLoading(false);
  }, [bookedSuccessfully]);

  return (
    <div className="main-content bg-primary">
      <header>
        <Navabar />
      </header>
      <main className="container flex-column-container padding-md">
        {bookedSuccessfully ? (
          <h1 className="fs-500 fw-500">
            Thank you for booking an appointment with us. We will reach out to
            you shortly.
          </h1>
        ) : (
          <>
            <h1 className="fs-500 fw-500">
              Provide your details below and a member of our dedicated team will
              promptly reach out to you.
            </h1>
            <form
              onSubmit={handleSubmit}
              className="flex-column-container padding-md"
            >
              <div className="flex-row-container flex-center">
                <span>Patient Type</span>
                <ToggleButtonGroup
                  exclusive
                  onChange={handleToggleUpdate}
                  value={customer.patientType ?? "New"}
                >
                  <ToggleButton
                    value="New"
                    aria-label="New patient toggle button"
                  >
                    <span>New</span>
                  </ToggleButton>
                  <ToggleButton
                    value="Existing"
                    aria-label="Existing patient toggle button"
                  >
                    <span>Existing</span>
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              <TextField
                className="full-width"
                label="Name"
                required
                value={customer?.name ?? ""}
                onChange={(e) =>
                  setCustomer({ ...customer, name: e.target.value })
                }
                type="text"
              />
              <TextField
                className="full-width"
                value={customer?.email ?? ""}
                onChange={(e) =>
                  setCustomer({ ...customer, email: e.target.value })
                }
                label="Email"
                required
                type="email"
              />
              <div className="flex-row-container-no-gap">
                <FormControl sx={{ m: 1, minWidth: 110 }}>
                  <Select
                    value={country.code}
                    label="Country *"
                    onChange={handleChange}
                  >
                    {countries.map((country) => (
                      <MenuItem key={country.code} value={country.code}>
                        <span className={country.className}></span>{" "}
                        {country.phoneCode}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  className="full-width"
                  required
                  type="text"
                  inputProps={{ pattern: `${country.mobilePhonePattern}` }}
                  label="Phone Number"
                  value={phoneTf}
                  error={!phoneValid}
                  onChange={(event) => {
                    setPhoneValidation(event.target.validity.valid);
                    setPhone(event.target.value);
                  }}
                />
              </div>
              <button type="submit" className="button">
                <span
                  className="flex-row-container fixed-height items-aligned"
                  hidden={!apiLoading}
                >
                  {apiLoading ? (
                    <CircularProgress size="1.6rem" color="success" />
                  ) : (
                    "Book Appointment"
                  )}
                </span>
              </button>
              <span className="flex-row-container flex-center fs-300">
                <Checkbox
                  onClick={(e) =>
                    setCustomer({
                      ...customer,
                      subsribedToNewsletter: !customer.subsribedToNewsletter,
                    })
                  }
                  icon={<CheckBoxOutlineBlank fontSize="large" color="info" />}
                  checkedIcon={<CheckBoxOutlined fontSize="large" color="info" />}
                  checked={customer?.subsribedToNewsletter ?? false}
                />{" "}
                Subscribe to our exclusive promotions & offers
              </span>
            </form>
          </>
        )}
      </main>
      <footer className="bg-dark-gradient">
        <Footer></Footer>
      </footer>
    </div>
  );
}
