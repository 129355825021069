import Footer from "../components/Footer/Footer";
import { Navabar } from "../components/Navbar/Navbar";
import { Testimonials } from "../components/Testimonials/Testimonials";

export default function AboutUs() {
  return (
    <div className="main-content bg-primary">
      <header className="padding-bottom-lg">
        <Navabar />
        <section>
          <div className="container">
            <div className="even-columns items-aligned">
              <div className="flex-column-container">
                <h1 className="text-accent fs-700 fw-600 line-height-100">
                  Faces & Braces - Care for the entire family, all under one
                  roof.
                </h1>
                <p className="fs-300 fw-400">
                  We are a family-friendly dental practice located in the heart
                  of the city. We offer a wide range of dental services,
                  including orthodontics, cosmetic dentistry, and facial
                  aesthetic. <br />
                  <br /> At Faces & Braces, we have built a reputation as a warm
                  and friendly place to go for the kind of care and comfort you
                  and your family deserve.
                </p>
              </div>
              <div className="self-center">
                <img
                  className="dentists-img"
                  src={`${process.env.PUBLIC_URL}/dentists.png`}
                  alt="Dentist and his assistant are doing patient examination in the clinic."
                />
              </div>
            </div>
          </div>
        </section>
      </header>
      <main>
        <section>
          <Testimonials />
        </section>
      </main>
      <footer className="bg-dark-gradient">
        <Footer></Footer>
      </footer>
    </div>
  );
}
