import Footer from "../components/Footer/Footer";
import { Navabar } from "../components/Navbar/Navbar";
import PageUnderConstruction from "./PageUnderConstruction";

export function PatientResources() {
    return (
      <div className="main-content bg-primary">
        <header>
          <Navabar />
        </header>
        <main>
          <PageUnderConstruction/>
        </main>
        <footer className="bg-dark-gradient">
          <Footer></Footer>
        </footer>
      </div>
    );
}