import { FacebookOutlined, Instagram, LinkedIn } from "@mui/icons-material";
import { Divider, IconButton } from "@mui/material"
import { NavLink } from "react-router-dom";

export default function Footer()
{
    return (
      <div className="container footer-container">
        <div className="even-columns">
          <section className="footer-section flex-column-container fs-300 fw-400 text-primary-white">
            <ul role="list">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <NavLink to="/about">About Us</NavLink>
              </li>
              <a href="#">
                <li>Dental Services</li>
              </a>
              <li>
                <NavLink to="/patient-resources">Patient Resources</NavLink>
              </li>
              <NavLink to="/contact-us">
                <li>Contact Us</li>
              </NavLink>
            </ul>
          </section>
          <section className="footer-section no-border">
            <h3 className="fs-300 fw-400 text-primary-white">
              Partners and Associations
            </h3>
            <div className="section-content">
              <img
                src={`${process.env.PUBLIC_URL}/logos/invisalign.png`}
                alt="Invisalign logo"
                loading="lazy"
              />
              <img
                src={`${process.env.PUBLIC_URL}/logos/dr-annej.png`}
                alt="Dr Anne J logo"
                loading="lazy"
              />
              <img
                src={`${process.env.PUBLIC_URL}/logos/ormco-logo.png`}
                alt="Ormco logo"
                loading="lazy"
              />
            </div>
          </section>
          {/* <section>
            <h3 className="fs-300 fw-400 text-primary-white">Follow Us</h3>
            <div>
              <IconButton>
                <FacebookOutlined />
              </IconButton>
              <IconButton>
                <Instagram />
              </IconButton>
              <IconButton>
                <LinkedIn />
              </IconButton>
            </div>
          </section> */}
        </div>
        <p className="text-centred fs-100 fw-400 text-primary-white ">
          © 2023 Faces & Braces. All Rights Reserved.
        </p>
      </div>
    );
}