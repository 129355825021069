
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ErrorPage from "./pages/ErrorPage";
import AboutUs from "./pages/AboutUs";
import { PatientResources } from "./pages/PatientResources";
import { ContactUs } from "./pages/ContactUs";
import { Orthodontics } from "./pages/Orthodntics";
import { FacialAesthetics } from "./pages/FacialAesthetics";
import { CosmeticDentistry } from "./pages/CosmeticDentistry";
import Home from "./pages/Home";
import ScrollToTop from "./helpers/ScrollToTop";
import BookAppointment from "./pages/BookAppointment";

export const App = () => {
  return (
    <BrowserRouter>
    <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} errorElement={<ErrorPage />}/>
          <Route
            path="/about"
            element={<AboutUs />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/patient-resources"
            element={<PatientResources />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/contact-us"
            element={<ContactUs />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="book-appointment"
            element={<BookAppointment/>}
            errorElement={<ErrorPage />}
          />
          <Route path="/services">
            <Route path="orthodontics" element={<Orthodontics />} />
            <Route path="facial-aesthetics" element={<FacialAesthetics />} />
            <Route path="cosmetic-dentistry" element={<CosmeticDentistry />} />
          </Route>
      </Routes>
    </BrowserRouter>
  );
};
