import { Link, Outlet } from "react-router-dom";

export interface CardProps {
  imgUrl: string;
  imgDescription: string;
  title: string;
  description: string;
  path: string;
}

export default function Card(props: CardProps) {
  const { imgUrl, imgDescription, title, description, path } =
    props;
  return (
    <div className="card-container">
      <div className="img-title-container">
        <img loading="lazy" src={imgUrl} alt={imgDescription} />
        <h3 className="card-title">{title.toUpperCase()}</h3>
      </div>
      <div className="card-content">
        <p className="fs-300 fw-500">{description}</p>
      </div>
      <div>
        <Link to={path}>
          <button className="button">MORE DETAILS</button>
        </Link>
      </div>
      <Outlet />
    </div>
  );
}
