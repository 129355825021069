import React, { useEffect, useReducer } from "react";
import { Link, NavLink, Router } from "react-router-dom";
import { BurgerButton } from "./BurgerButton";
import { SideMenu } from "../SideMenu/SideMenu";


export const Navabar = () => {
    const [open, setOpen] = React.useState(false);
    const primaryNav = document.querySelector(".primary-navigation");
    const body = document.querySelector("body");
    useEffect(() => {
      primaryNav?.classList.toggle("animate");
      primaryNav?.classList.toggle("pop");
      primaryNav?.classList.toggle('opened')
      body?.toggleAttribute('data-overlay', open)
    }, [open])
    return (
      <div className="container nav-wrapper">
        <NavLink to="/">
          <svg width={150} height={85} className="logo"></svg>
        </NavLink>
        <NavLink to="/book-appointment">
          <button className="button hide-mobile">Book an Appointment</button>
        </NavLink>
        <BurgerButton open={open} setOpen={setOpen} aria-expanded={open} />
        <nav className="primary-navigation" id="primary-navigation">
          <ul arial-label="Primary" className="nav-list" role="list">
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <NavLink to="/about">About Us</NavLink>
            </li>
            <a onClick={() => setOpen(false)} href="#services">
              <li>Dental Services</li>
            </a>
            <li>
              <NavLink to="/patient-resources">Patient Resources</NavLink>
            </li>
            <NavLink to="/contact-us">
              <li>Contact Us</li>
            </NavLink>
          </ul>
        </nav>
      </div>
    );
}