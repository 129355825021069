import Footer from "../components/Footer/Footer";
import { Navabar } from "../components/Navbar/Navbar";

export function FacialAesthetics() {
  return (
    <>
      <header>
        <Navabar />
      </header>
      <main></main>
      <footer className="bg-dark-gradient">
        <Footer></Footer>
      </footer>
    </>
  );
}
