type HttpResponseType = 'error' | 'success' | 'unauthorized';

export interface HttpRespond<T = void> extends Response {
  httpType?: HttpResponseType;
  parsedBody?: T;
  error?: Error;
}

export async function MakeHttpRequest<T = void>(
  request: RequestInfo
): Promise<HttpRespond<T>> {
  try {
    const response: HttpRespond<T> = await fetch(request);
    try {
      // may error if there is no body
      response.parsedBody = await response.json();
    } catch (ex: any) {}
    if (!response.ok) {
      return {
        ...response,
        httpType: response.status == 401 ? 'unauthorized' : 'error',
        error: new Error(response.statusText),
      };
    }
    console.log('Returnning ok');
    return { ...response, httpType: 'success' };
  } catch (ex: any) {
    //create network error.
    console.log('catch');
    let networkError = Response.error();
    let specificError = new Error(ex);
    return { ...networkError, httpType: 'error', error: specificError };
  }
}

export async function Get<T = void>(
  path: string,
  headers?: any,
  args: RequestInit = {
    method: 'get',
    headers:
      headers !== undefined
        ? headers
        : {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
  }
): Promise<HttpRespond<T>> {
  return await MakeHttpRequest<T>(new Request(path, args));
}

export function Post<T = void>(
  path: string,
  body?: any,
  headers?: any,
  args: RequestInit = {
    method: 'post',
    body: JSON.stringify(body),
    headers:
      headers !== undefined
        ? headers
        : {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
  }
): Promise<HttpRespond<T>> {
  let request = new Request(path, args);
  console.log('Request', request);
  return MakeHttpRequest<T>(new Request(path, args)).then(response => {
    console.log('Response', response);
    return response;
  });
}

export async function Put<T = void>(
  path: string,
  body?: any,
  headers?: any,
  args: RequestInit = {
    method: 'put',
    body: JSON.stringify(body),
    headers: headers ?? {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
): Promise<HttpRespond<T>> {
  return await MakeHttpRequest<T>(new Request(path, args));
}
