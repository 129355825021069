import { useEffect, useState } from "react";
import {
  TestimonialCard,
  TestimonialCardProps,
} from "../TestimonialCard/TestimonialCard";
import { IconButton } from "@mui/material";
import {
  ArrowLeft,
  ArrowLeftOutlined,
  ArrowRight,
  ArrowRightOutlined,
} from "@mui/icons-material";

function retrieveTestimonials(): TestimonialCardProps[] {
  return [
    {
      name: "Denis",
      source: "Facebook",
      quote:
        "Their expertise and genuine passion for improving smiles assured me that I was in capable hands. The attention to detail and commitment to achieving the best results were evident at every step. My treatment plan was personalized, addressing my needs and ensuring my comfort throughout.",
      rating: 5,
    },
    {
      name: "Debora",
      source: "Facebook",
      quote:
        "I had an exceptional experience at Faces and Braces! The team provided top-notch orthodontic care with professionalism and warmth. The personalized treatment plan, cutting-edge facilities, and patient education exceeded my expectations. Now, thanks to Faces and Braces, I have a confident smile. Highly recommended!",
      rating: 5,
    },
    {
      name: "Jordan",
      source: "Google",
      quote:
        "My experience at Faces and Braces was awesome! They made my teeth look great and made me feel at home. The team was super friendly and taught me how to keep my teeth healthy. Thanks to them, I have a fantastic smile now. I highly recommend Faces and Braces for anyone looking to improve their smile!",
      rating: 5,
    },
  ];
}

export function Testimonials() {
  const [testimonials, setTestimonials] = useState<TestimonialCardProps[]>([]);
  const [activeTestimonialIndex, setActiveTestimonialIndex] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    setTestimonials(retrieveTestimonials());
  }, []);

  useEffect(() => {
    setActiveTestimonialIndex(0);
  }, [testimonials]);

  return (
    <div className="testimonials-wrapper padding-bottom-lg">
      <h1 className="fw-700 fs-700 text-centred mobile-only-padding-bottom-md">Testimonials</h1>
      <div className="testimonials-container">
        <div>
          <IconButton className="left-button">
            <ArrowLeftOutlined
              fontSize="large"
              onClick={() => {
                setActiveTestimonialIndex((prev) => {
                  if (prev === undefined) {
                    return 0;
                  }
                  if (prev === 0) {
                    return testimonials.length - 1;
                  }
                  return prev - 1;
                });
              }}
            />
          </IconButton>
        </div>
        <div className="content">
          {testimonials.map((testimonial, key) => {
            return (
              <div
                className={
                  key === activeTestimonialIndex ? "content" : "content hidden"
                }
                key={key}
              >
                <TestimonialCard
                  rating={testimonial.rating}
                  name={testimonial.name}
                  quote={testimonial.quote}
                  source={testimonial.source}
                />
              </div>
            );
          })}
        </div>
        <div>
          <IconButton className="right-button">
            <ArrowRightOutlined
              fontSize="large"
              onClick={() => {
                setActiveTestimonialIndex((prev) => {
                  if (prev === undefined) {
                    return 0;
                  }
                  if (prev === testimonials.length - 1) {
                    return 0;
                  }
                  return prev + 1;
                });
              }}
            />
          </IconButton>
        </div>
      </div>
    </div>
  );
}
