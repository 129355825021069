import { useEffect, useState } from "react";
import Footer from "../components/Footer/Footer";
import Hero from "../components/Hero/Hero";
import Strap from "../components/Strap/Strap";
import { Testimonials } from "../components/Testimonials/Testimonials";
import Treatments from "../components/Treatments/Treatments";
import { Loading } from "../components/Loading/Loading";
import { Navabar } from "../components/Navbar/Navbar";

export default function Home() {
  const [fontLoaded, setFontLoaded] = useState<boolean>(false);

  useEffect(() => {
    document.fonts.ready.then(() => {
      setFontLoaded(true);
    });
  }, []); // Empty dependency array ensures the effect runs once
  return !fontLoaded ? (
    <div>
      <Loading />
    </div>
  ) : (
    <div className="bg-primary">
      <main>
        <div className="hero-wrapper">
          <header>
            <Navabar />
          </header>
          <section>
            <Hero />
          </section>
          <section className="bg-purple at-bottom">
            <Strap />
          </section>
        </div>
        <section id="services" className="bg-dimmed">
          <Treatments />
        </section>
        <section className="bg-dimmed">
          <Testimonials />
        </section>
      </main>
      <footer className="bg-dark-gradient">
        <Footer></Footer>
      </footer>
    </div>
  );
}
