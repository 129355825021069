import React from "react";
export const Loading: React.FC = () => {
  return (
    <div className="preloader">
      <div className="spinner_wrap">
        <div className="spinner"></div>
      </div>
    </div>
  );
};
