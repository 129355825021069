import { NavLink } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import { Navabar } from "../components/Navbar/Navbar";

export function Orthodontics() {
    return (
      <>
        <header className="bg-primary">
          <Navabar />
          <section>
            <div className="container">
              <div className="even-columns items-aligned">
                <div className="flex-column-container">
                  <h1 className="text-primary fs-700 fw-700 line-height-300">
                    Faces & Braces <br />
                    Dental Orthodontics
                  </h1>
                  <p className="fs-300 fw-400 text-balance">
                    Because every individual’s goals and issues are different
                    and deserve personalized attention, there is no charge for
                    your initial consultation at Faces and Braces Orthodontics
                  </p>
                  <div className="grid-responsive items-aligned bg-white curved">
                    <NavLink to="/book-appointment">
                      <button className="button">BOOK FREE CONSULTATION</button>
                    </NavLink>
                    <p className="text-centred fw-700">
                      OR CALL: (044) 934 0370
                    </p>
                  </div>
                </div>
                <div>
                  <img
                    className="hero-image"
                    src={`${process.env.PUBLIC_URL}/HeroImage.png`}
                    alt="dental braces in the hand"
                  />
                </div>
              </div>
            </div>
          </section>
        </header>
        <main className="padding-bottom-lg">
          <section className="bg-muted">
            <div className="container">
              <div className="ortho-custom-grid padding-sm">
                <div className="ortho-1">
                  <div className="flex-row-container flex-center ortho-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="64"
                      height="64"
                      viewBox="0 0 64 64"
                      fill="none"
                    >
                      <path
                        d="M18.6668 5.33333C10.6668 5.33333 5.3335 13.3333 5.3335 21.3333C5.3335 26.96 8.00016 34.6667 10.6668 37.3333C13.3335 40 16.0002 58.6667 21.3335 58.6667C33.4402 58.6667 26.6668 40 32.0002 40C37.3335 40 30.5602 58.6667 42.6668 58.6667C48.0002 58.6667 50.6668 40 53.3335 37.3333C56.0002 34.6667 58.6668 26.96 58.6668 21.3333C58.6668 13.3333 53.3335 5.33333 45.3335 5.33333C37.3335 5.33333 37.3335 7.99999 32.0002 7.99999C26.6668 7.99999 26.6668 5.33333 18.6668 5.33333ZM18.6668 10.6667C24.0002 10.6667 26.6668 13.3333 32.0002 13.3333C37.3335 13.3333 40.0002 10.6667 45.3335 10.6667C49.7868 10.6667 53.3335 16 53.3335 21.3333C53.3335 26 51.0402 32.2933 48.5068 34.8267C46.2135 37.12 42.8268 53.1733 41.3335 53.1733C40.7735 53.1733 40.0002 50.3467 40.0002 46.9067C40.0002 41.4667 38.4802 34.6667 32.0002 34.6667C25.5202 34.6667 24.0002 41.4667 24.0002 46.9067C24.0002 50.3467 23.2268 53.1733 22.6668 53.1733C21.1735 53.1733 17.7868 37.12 15.4935 34.8267C12.9602 32.2933 10.6668 26 10.6668 21.3333C10.6668 16 14.2135 10.6667 18.6668 10.6667Z"
                        fill="black"
                      />
                    </svg>
                    <h1 className="fs-500 fw-500">
                      Why orthodontics treatment is important?
                    </h1>
                  </div>
                  <p className="fs-300 line-height-700 padding-sm">
                    Crooked and crowded teeth are hard to clean and maintain. A
                    bad bite can also cause abnormal wear of tooth surfaces,
                    difficulty in chewing and/or speaking, and excess stress on
                    supporting bone and gum tissue. In addition to improving
                    oral health, orthodontic treatment can provide the
                    confidence that comes with a healthy, attractive smile for
                    years to come.
                  </p>
                </div>
                <div className="ortho-2">
                  <div className="flex-row-container flex-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="64"
                      height="64"
                      viewBox="0 0 64 64"
                      fill="none"
                    >
                      <path
                        d="M50.6667 50.6667H13.3333V21.3333H50.6667M42.6667 2.66666V7.99999H21.3333V2.66666H16V7.99999H13.3333C10.3733 7.99999 8 10.3733 8 13.3333V50.6667C8 52.0811 8.5619 53.4377 9.5621 54.4379C10.5623 55.4381 11.9188 56 13.3333 56H50.6667C52.0812 56 53.4377 55.4381 54.4379 54.4379C55.4381 53.4377 56 52.0811 56 50.6667V13.3333C56 11.9188 55.4381 10.5623 54.4379 9.56209C53.4377 8.56189 52.0812 7.99999 50.6667 7.99999H48V2.66666M45.3333 32H32V45.3333H45.3333V32Z"
                        fill="black"
                      />
                    </svg>
                    <h1 className="fs-500 fw-500">
                      When should children get an orthodontic check-up?
                    </h1>
                  </div>
                  <p className="fs-300 line-height-700 padding-sm">
                    The American Association of Orthodontists recommends that
                    children get an orthodontic check-up no later than age 7. By
                    this age, orthodontists can spot subtle problems with jaw
                    growth and emerging teeth while baby teeth are still
                    present. That’s important because some orthodontic problems
                    may be easier to correct if they are found early. Your
                    pediatric dentist at Inspire Dental can help determine if
                    your child should see their colleagues at Inspire
                    Orthodontics. Most orthodontic patients begin active
                    treatment between ages 9 and 14.
                  </p>
                </div>
                <div className="ortho-3">
                  <div className="flex-row-container flex-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="64"
                      height="64"
                      viewBox="0 0 64 64"
                      fill="none"
                    >
                      <path
                        d="M32 14.6667C34.4754 14.6667 36.8493 15.65 38.5997 17.4004C40.35 19.1507 41.3333 21.5247 41.3333 24C41.3333 26.4754 40.35 28.8493 38.5997 30.5997C36.8493 32.35 34.4754 33.3334 32 33.3334C29.5246 33.3334 27.1507 32.35 25.4003 30.5997C23.65 28.8493 22.6667 26.4754 22.6667 24C22.6667 21.5247 23.65 19.1507 25.4003 17.4004C27.1507 15.65 29.5246 14.6667 32 14.6667ZM13.3333 21.3334C14.8267 21.3334 16.2133 21.7334 17.4133 22.4534C17.0133 26.2667 18.1333 30.0534 20.4267 33.0134C19.0933 35.5734 16.4267 37.3334 13.3333 37.3334C11.2116 37.3334 9.17677 36.4905 7.67648 34.9902C6.17619 33.4899 5.33333 31.4551 5.33333 29.3334C5.33333 27.2116 6.17619 25.1768 7.67648 23.6765C9.17677 22.1762 11.2116 21.3334 13.3333 21.3334ZM50.6667 21.3334C52.7884 21.3334 54.8232 22.1762 56.3235 23.6765C57.8238 25.1768 58.6667 27.2116 58.6667 29.3334C58.6667 31.4551 57.8238 33.4899 56.3235 34.9902C54.8232 36.4905 52.7884 37.3334 50.6667 37.3334C47.5733 37.3334 44.9067 35.5734 43.5733 33.0134C45.8983 30.0118 46.9775 26.2299 46.5867 22.4534C47.7867 21.7334 49.1733 21.3334 50.6667 21.3334ZM14.6667 48.6667C14.6667 43.1467 22.4267 38.6667 32 38.6667C41.5733 38.6667 49.3333 43.1467 49.3333 48.6667V53.3334H14.6667V48.6667ZM0 53.3334V49.3334C0 45.6267 5.04 42.5067 11.8667 41.6C10.2933 43.4134 9.33333 45.92 9.33333 48.6667V53.3334H0ZM64 53.3334H54.6667V48.6667C54.6667 45.92 53.7067 43.4134 52.1333 41.6C58.96 42.5067 64 45.6267 64 49.3334V53.3334Z"
                        fill="black"
                      />
                    </svg>
                    <h1 className="fs-500 fw-500">
                      Can adults benefit from orthodontic treatment?
                    </h1>
                  </div>
                  <p className="fs-300 line-height-700 padding-sm">
                    Orthodontic treatment can be successful at almost any age.
                    In fact, about one in every five orthodontic patients today
                    is over age 18. Thanks to today’s smaller, less visible,
                    more comfortable orthodontic appliances including ceramic
                    (tooth colored) brackets and clear aligners, adults are
                    finding treatment more appealing.
                  </p>
                </div>
                <img
                  className="hero-image img-1"
                  src={`${process.env.PUBLIC_URL}/ortho-1.png`}
                  alt="Man is smiling and holding dental brace in the hand"
                />
                <iframe
                  className="img-2"
                  src="https://www.youtube.com/embed/l2uQyBCmj9I?si=ZBDRwfxxnDktIJdS"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                ></iframe>
              </div>
            </div>
          </section>
        </main>
        <footer className="bg-dark-gradient">
          <Footer></Footer>
        </footer>
      </>
    );
}