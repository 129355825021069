import { Rating } from "@mui/material";

export interface TestimonialCardProps {
    rating: number;
    name: string;
    quote: string;
    source: string;
}

export function TestimonialCard(props: TestimonialCardProps) {
    return (
      <div className="flex-column-container">
        <section>
          <Rating name="read-only" value={props.rating} readOnly />
        </section>
        <section className="fs-300 text-balance fs-italic">
          <p>{props.quote}</p>
        </section>
        <section>
          <p className="fs-300">{props.name}</p>
        </section>
      </div>
    );
}