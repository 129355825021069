import Construction from "../components/UnderConstruction/Construction";

export default function PageUnderConstruction() {
  return (
    <div className="container even-columns items-aligned">
      <div>
        <h1 className="text-primary fs-900 fw-700 line-height-300 animate-hidden">
          Page Under Construction
        </h1>
        <p className="fs-300 fw-400 text-balance">
          We're sorry, but this page is currently under construction. Please
          check back later!
        </p>
      </div>
      <div className="padding-md">
        <Construction/>
      </div>
    </div>
  );
}
