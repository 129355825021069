
import { Link, redirect, useLocation } from "react-router-dom";
import Card from "../Card/Card";

export default function Treatments() {
  return (
    <div className="container">
      <h1 className="fw-700 fs-900 text-centred">Treatments</h1>
      <div className="scrollable-flex-row-container flex-center padding-md">
        <Card
          title="Orthodontics"
          description="Removable appliance, Fixed appliance, Fixed braces, Clear aligner & Invisalign"
          imgUrl={`${process.env.PUBLIC_URL}/ortho.png`}
          imgDescription="Girl is putting an brace on her teeth"
          path="/services/orthodontics"
        />
        <Card
          title="Facial Aesthetics"
          description="Anti-wrinkle Treatments, Filler & Profhilo®"
          imgUrl={`${process.env.PUBLIC_URL}/facial-aesthetics.png`}
          imgDescription="Injection in the young girls face around upper lips"
          path="/services/facial-aesthetics"
        />
        <Card
          title="Cosmetic Dentistry"
          description="Composite bonding and teeth whitening"
          imgUrl={`${process.env.PUBLIC_URL}/cosmetic-dentistry.png`}
          imgDescription="Young girl with curly hair with perfect smile and white teeth"
          path="/services/cosmetic-dentistry"
        />
      </div>
    </div>
  );
}
