import { useEffect, useRef } from "react";

export interface BurgerButtonProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const BurgerButton = (props: BurgerButtonProps) => {
  const { open, setOpen } = props;

  useEffect(() => {
    const button = document.getElementById("burger");
    const currentState = button?.getAttribute("data-state");

    const opened = currentState === "opened";
    console.info("Current state: ", currentState);
    console.info("Open: ", open);
    console.info("Opened: ", opened);
    if(opened === open)
        return;

    if (!currentState || currentState === "closed") {
      button!.setAttribute("data-state", "opened");
      button!.setAttribute("aria-expanded", "true");
    } else {
      button!.setAttribute("data-state", "closed");
      button!.setAttribute("aria-expanded", "false");
    }
  }, [open]);

  return (
    <button
      onClick={() => setOpen(!open)}
      id="burger"
      className="burger-button"
      aria-expanded="false"
    >
      <svg stroke="#000" className="hamburger" viewBox="0 0 100 100" width="50">
        <line
          className="line top"
          x1="90"
          x2="10"
          y1="40"
          y2="40"
          stroke-width="10"
          stroke-linecap="round"
          stroke-dasharray="80"
          stroke-dashoffset="0"
        ></line>
        <line
          className="line bottom"
          x1="10"
          x2="90"
          y1="60"
          y2="60"
          stroke-width="10"
          stroke-linecap="round"
          stroke-dasharray="80"
          stroke-dashoffset="0"
        ></line>
      </svg>
    </button>
  );
};
