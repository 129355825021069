import { CallOutlined, PhoneIphoneOutlined, PhonelinkLockOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { NavLink } from "react-router-dom";

export default function Hero() {
  return (
    <div className="container">
      <div className="even-columns items-aligned">
        <div className="flex-column-container">
          <h1 className="text-primary fs-900 fw-700 line-height-300 animate-hidden">
            Dental done <span className="text-accent">differently</span>
          </h1>
          <p className="fs-300 fw-400 text-balance">
            Experience a brighter, healthier smile with personalized <br />
            <span>care at Faces & Braces.</span>
          </p>
          <div className="grid-responsive items-aligned bg-white curved">
            <NavLink to="/book-appointment">
              <button className="button">BOOK FREE CONSULTATION</button>
            </NavLink>
            <div className="flex-row-container flex-center content-flex-center">
              <div>
                <p className="text-centred fw-700">OR CALL: (044) 934 0370</p>
              </div>
              <div className="mobile-only">
                <a className="flex-row-container" href="tel:0449340370">
                  <CallOutlined color="success" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="content-flex-center">
          <img
            className="img-hero"
            src={`${process.env.PUBLIC_URL}/HeroImage.png`}
            alt="dental braces in the hand"
          />
        </div>
      </div>
    </div>
  );
}
