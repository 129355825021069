import Footer from "../components/Footer/Footer";
import { Navabar } from "../components/Navbar/Navbar";
import Construction from "../components/UnderConstruction/Construction";
import PageUnderConstruction from "./PageUnderConstruction";

export function ContactUs() {
  return (
    <div className="main-content bg-primary">
      <header>
        <Navabar />
      </header>
      <main>
        <PageUnderConstruction/>
      </main>
      <footer className="bg-dark-gradient">
        <Footer></Footer>
      </footer>
    </div>
  );
}
